import _ from 'lodash';
import moment from 'moment-timezone';
import AppService from '../shared/App.api';

export default {
  validateStickerNames,
  validateSubmit,
  validateCategorySubmit,
  getStickers,
  getStickerCategories,
  getStartEndTime,
  uploadStickerFile,
  createStickers,
  updateStickers,
  endStickers,
  createCategory,
  updateCategory,
};

/**
 * Validate the category object before submitting
 * @param {Object} category - category object for validation
 * @returns Errors array, empty or containing error messages
 */
function validateCategorySubmit(category) {
  const errors = [];

  if (!category) {
    errors.push('Please enter a name and image for the category');
    return errors;
  }

  if (!category.name) {
    errors.push('Please enter a name for the category');
    return errors;
  }

  const invalidName = validateStickerNames([{ name: category.name }]);
  if (invalidName) {
    errors.push(
      'Invalid name. Please make sure the name contains only letters, numbers, dashes, spaces, or underscores.'
    );
  }

  if (!category.image) {
    errors.push('Please upload an image for the category');
  }

  return errors;
}

/**
 * Checks the sticker names to make sure they're valid, then returns the invalid
 * ones so they can be styled in the UI as erroring
 * @param {Array} stickers - array of sticker objects to validate the names
 * @returns an array of invalid sticker names, or false if all names are valid
 */
function validateStickerNames(stickers) {
  const invalidNames = [];
  _.forEach(stickers, (sticker) => {
    const validation = validateName(sticker.name);
    if (validation.error) {
      invalidNames.push(sticker.name);
    }
  });

  if (invalidNames.length > 0) {
    return invalidNames;
  }

  return false;
}

/**
 * Validates the sticker submission form before sending to the API
 * @param {Array} uploadedStickers - array of sticker objects
 * @param {Object} selectedCategory - category object
 * @param {date} startDate - start date
 * @param {date} endDate - end date (will not be null at this stage - should be a date or "now")
 * @returns Array with the first error encountered, empty if no errors
 */
function validateSubmit(
  uploadedStickers,
  selectedCategory,
  startDate,
  endDate,
  options = {}
) {
  const errors = [];
  const { isMultipleEdit = false, reactivateCategory = false } = options;
  if (uploadedStickers) {
    const invalidNames = validateStickerNames(uploadedStickers);

    if (invalidNames) {
      errors.push(
        'One or more stickers has an invalid name. Please make sure names contain only letters, numbers, dashes, spaces, or underscores.'
      );
    }
  }

  switch (true) {
    case uploadedStickers.length === 0 && !reactivateCategory:
      errors.push('Please upload at least one sticker before submitting');
      break;
    case _.filter(uploadedStickers, (sticker) => !sticker.image).length > 0:
      errors.push('Please upload an image for each sticker');
      break;
    case _.filter(uploadedStickers, (sticker) => !sticker.name).length > 0:
      errors.push('Please enter a name for each sticker');
      break;
    case !selectedCategory:
      errors.push('Please select a category');
      break;
    case startDate &&
      endDate &&
      getStartEndTime(startDate, 'start') > getStartEndTime(endDate, 'end'):
      errors.push('Start date cannot be after the end date');
      break;
    case isMultipleEdit:
      break;
    case startDate &&
      getStartEndTime(startDate, 'start') < getStartEndTime(moment(), 'start'):
      errors.push('Start date cannot be in the past');
      break;
    case endDate &&
      getStartEndTime(endDate, 'end') < getStartEndTime(moment(), 'end'):
      errors.push('End date cannot be in the past');
      break;
    case !endDate:
      errors.push(
        'Please select an end date or "Evergreen" if the sticker(s) should not expire'
      );
      break;
    case !startDate:
      errors.push('Please select a start date');
      break;
    default:
      break;
  }

  return errors;
}

/**
 * Validates the name of a sticker or category
 * @param {string} name - name to validate
 * @returns true if the name is valid, or an object with an error message if invalid
 */
function validateName(name) {
  const nameRegex = /^[a-zA-Z0-9\s-_]+$/;
  const isValid = nameRegex.test(name);
  const isString = typeof name === 'string';

  if (isValid && isString) {
    return true;
  }
  return {
    error: true,
    message:
      'Invalid name - please use only letters, numbers, spaces, hyphens, and underscores.',
  };
}

/**
 * Get the start or end time of the day in CST; the server will translate to UTC before saving to DB
 * Cast all dates to start of the day (midnight morning of date) or end of the day (11:59:59pm) in CST
 * @param {string} date - date to convert to CST
 * @param {string} timeOfDay - 'start' or 'end' of the day
 * @returns the date in CST formatted as 'YYYY-MM-DD HH:mm:ss'
 */
function getStartEndTime(date, timeOfDay) {
  const cstMoment = moment.tz(date, 'America/Chicago');
  if (timeOfDay === 'start') {
    cstMoment.startOf('day');
  } else {
    // if we do the end of the day selected, it'll be a day early because of the timezone offset
    // without this, 11-22-24 23:59:59 CST would be 2024-11-22T05:59:59Z in the DB, making it end on 11-21 at 11:59:59pm
    // so here, 11-22-24 23:59:59 CST will be saved to the DB as 11-23-24 05:59:59. Minus 6 hours to get the correct date
    if (cstMoment.date() < moment(date).date()) {
      cstMoment.add(1, 'day');
    }
    cstMoment.endOf('day');
  }
  return cstMoment.format('YYYY-MM-DD HH:mm:ss [CST]');
}

/** ********** API functions *********** */

const stickersUrl = '/admin/stickers';

/**
 *
 * @param {boolean} activeOnly - whether to get all stickers, or only active
 * @returns an array containing sticker objects with their category on each object
 */
async function getStickers(activeOnly) {
  const response = await AppService.get(
    `${stickersUrl}/${activeOnly ? '' : '?type=all'}`
  );
  return response;
}

/**
 *
 * @param {boolean} activeOnly - whether to get all categories, or only active
 * @returns an array containing the categories
 */
async function getStickerCategories(activeOnly) {
  const response = await AppService.get(
    `${stickersUrl}/categories${activeOnly ? '' : '?type=all'}`
  );

  if (!response.status === 200 || !response.data) {
    throw new Error('Failed to get categories', response);
  }

  return response.data;
}

async function uploadStickerFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  const response = await AppService.post(`${stickersUrl}/upload`, formData);

  if (!response.status === 200 || !response.data.fileUrl) {
    throw new Error('Failed to upload sticker', response);
  }

  return response.data.fileUrl;
}

async function createStickers(stickerArray) {
  const response = await AppService.post(`${stickersUrl}`, stickerArray);

  if (!response.status === 200 || !response.data) {
    throw new Error('Failed to create sticker', response);
  }

  return response.data;
}

async function updateStickers(stickerArray) {
  const response = await AppService.patch(`${stickersUrl}`, stickerArray);
  if (!response.status === 200 || !response.data) {
    throw new Error('Failed to update sticker', response);
  }

  return response.data;
}

async function endStickers(stickerIds) {
  const response = await AppService.patch(`${stickersUrl}/end`, stickerIds);

  if (!response.status === 200 || !response.data) {
    throw new Error('Failed to end sticker', response);
  }

  return response.data;
}

async function createCategory(category) {
  const response = await AppService.post(`${stickersUrl}/categories`, category);

  if (!response.status === 200 || !response.data) {
    throw new Error('Failed to create category', response);
  }

  return response.data;
}

async function updateCategory(category) {
  const response = await AppService.patch(
    `${stickersUrl}/categories/${category.id}`,
    category
  );

  if (!response.status === 200 || !response.data) {
    throw new Error('Failed to update category', response);
  }

  return response.data;
}
