import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Typography,
  Modal,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Tab,
  Tabs,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import _ from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useUser } from '../auth/User.context';
import styleColors from '../colors.styles';
import StickersService from './Stickers.service';
import LoggingService from '../shared/Logging.api';
import { AlertsContext } from '../shared/alerts/Alerts.context';
import StickerList from './StickerList';
import StickersCreateForm from './StickersCreateForm';

const StickerCategoryReactivateModal = ({
  category,
  closeModal,
  categoryStickers,
  refetch,
  endStickers,
}) => {
  const smallScreen = !useMediaQuery('(min-width:900px)');

  // keeping track of selected stickers and start and end date submitted in the form
  const [startDate, setStartDate] = useState(moment());
  const [selectedStickers, setSelectedStickers] = useState([]);

  // errors and warnings
  const [error, setError] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { showAlert } = useContext(AlertsContext);

  const {
    state: { displayName },
  } = useUser();

  useEffect(() => {
    if (selectedStickers && selectedStickers.length === 0) {
      setStartDate(moment());
    }
    if (selectedStickers && selectedStickers.length === 1) {
      setStartDate(moment(selectedStickers[0].start_date));
    }
    if (selectedStickers && selectedStickers.length > 1) {
      const uniqueStartDates = _.uniq(_.map(selectedStickers, 'start_date'));
      if (uniqueStartDates.length === 1) {
        setStartDate(moment(uniqueStartDates[0]));
      } else {
        setStartDate(null);
      }
    }
  }, []);

  useEffect(() => {
    // if there are currently errors, re-validate to see if any have cleared
    if (error.length > 0) {
      const errors = StickersService.validateSubmit(
        selectedStickers,
        category,
        startDate,
        'now',
        { reactivateCategory: true }
      );
      setError(errors);
    }
  }, [category, startDate, selectedStickers]);

  const styles = {
    box: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'white',
      width: '800px',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflow: 'scroll',
      maxHeight: '90vh',
    },
    closeModalButton: {
      position: 'absolute',
      top: 20,
      zIndex: 100,
      right: 20,
    },
    error: {
      padding: '10px',
      backgroundColor: styleColors.base.error,
      borderRadius: '5px',
      margin: '20px',
      height: '20%',
    },
    stickerSheetContainer: {
      paddingBottom: smallScreen ? 0 : '30px',
      minWidth: '300px',
    },
    selectAllTab: {
      marginLeft: 'auto',
      textAlign: 'right',
      color:
        selectedStickers.length === 0
          ? styleColors.base.walmart_blue
          : styleColors.base.medium_gray,
    },
    deselectAllTab: {
      textAlign: 'right',
      color:
        selectedStickers.length > 0
          ? styleColors.base.walmart_blue
          : styleColors.base.medium_gray,
    },
    stickerList: {
      width: '100%',
      minWidth: '550px',
      height: '95%',
      maxHeight: `${categoryStickers.length * 40}px`,
      backgroundColor: 'black',
      padding: '20px',
      minHeight: '15vh',
      marginTop: 0,
      borderRadius: '3px',
      color: 'white',
    },
    categoryImage: {
      width: '30px',
      height: '30px',
      objectFit: 'contain',
    },
    tabIndicator: {
      backgroundColor: styleColors.base.active,
      height: '10px',
    },
    helpText: {
      margin: '20px 0',
      gridColumnStart: 1,
      gridColumnEnd: 4,
      textAlign: 'left',
    },
  };

  // function to pass down to the StickerList and handle when a sticker is clicked
  const handleSelectSticker = (sticker) => {
    if (_.find(selectedStickers, sticker)) {
      setSelectedStickers(
        _.filter(
          selectedStickers,
          (selectedSticker) => selectedSticker.id !== sticker.id
        )
      );
    } else {
      setSelectedStickers([...selectedStickers, sticker]);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    // final validation
    const errors = StickersService.validateSubmit(
      selectedStickers,
      category,
      startDate,
      'now',
      { reactivateCategory: true }
    );
    if (errors.length > 0) {
      setError(errors);
      setSubmitLoading(false);
      return;
    }

    const reactivateCategory = async () => {
      try {
        await StickersService.updateCategory({
          ...category,
          enabled: 1,
        });
      } catch (err) {
        LoggingService.error('Error reactivating category', err);
        showAlert('Error reactivating category', 'error');
      }
      refetch();
    };

    const editedStickers = selectedStickers.map((sticker) => ({
      ...sticker,
      name: _.trim(sticker.name),
      start_date: StickersService.getStartEndTime(startDate, 'start'),
      end_date: null,
      category: category.id,
      updated_by: _.trim(displayName).toLowerCase(),
    }));

    // update the start/end date of the selected stickers, reactivate the category
    // and end any stickers that were not selected in the case of evergreen or future end dates that were left in an archived category
    let response;
    try {
      response = await StickersService.updateStickers(editedStickers);
      if (selectedStickers.length < categoryStickers.length) {
        const unselectedStickers = _.differenceBy(
          categoryStickers,
          selectedStickers,
          'id'
        );
        await endStickers(unselectedStickers);
      }

      reactivateCategory();
    } catch (err) {
      LoggingService.error('Error reactivating category', err);
      showAlert('Error reactivating category', 'error');
    }

    if (response.length > 0) {
      showAlert('Category successfully reactivated', 'success');
    }

    setSubmitLoading(false);
    closeModal();
  };

  const handleInputChange = (name, value) => {
    // no validation here, because it happens in the useEffect when these things change
    if (name === 'startDate') {
      setStartDate(value);
    }
  };

  /* Render functions - broken out to keep the return readable */

  const renderDialog = () => {
    return (
      <Dialog open={dialogOpen}>
        <DialogTitle>
          By closing this modal, you will lose your progress.{' '}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Stay</Button>
          <Button onClick={() => closeModal()}>Leave</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {renderDialog()}
      <Modal open>
        <>
          <Box sx={styles.box}>
            <CancelIcon
              onClick={() => setDialogOpen(true)}
              style={styles.closeModalButton}
            />
            <Typography variant="h4" style={{ marginBottom: '30px' }}>
              Reactivate Category
            </Typography>
            <Typography
              style={{
                ...styles.error,
                visibility: error.length > 0 ? '' : 'hidden',
              }}
            >
              {error[0]}
            </Typography>
            <div style={styles.stickerSheetContainer}>
              <Typography style={styles.helpText}>
                Select the stickers in this category to reactivate
              </Typography>
              <Tabs
                style={styles.stickerTabs}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: styles.tabIndicator,
                }}
                value={category.id}
              >
                <Tab
                  key={category.id}
                  label={category.name}
                  value={category.id}
                  icon={
                    <img
                      src={category.image}
                      alt={category.name}
                      style={styles.categoryImage}
                    />
                  }
                />
                <Tab
                  label="Select All"
                  value={null}
                  onClick={() => {
                    setSelectedStickers(categoryStickers);
                  }}
                  style={styles.selectAllTab}
                />
                <Tab
                  label="Deselect All"
                  value={null}
                  onClick={() => {
                    setSelectedStickers([]);
                  }}
                  style={styles.deselectAllTab}
                />
              </Tabs>
              <StickerList
                stickers={categoryStickers}
                handleSelect={handleSelectSticker}
                selectedStickers={selectedStickers}
                stickerListStyle={styles.stickerList}
                isReactivation
              />
            </div>
            <StickersCreateForm
              categories={[category]}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              selectedCategory={category.name}
              error={error[0] || ''}
              submitLoading={submitLoading}
              startDate={startDate}
              selectedStickers={selectedStickers}
              forceEvergreen
            />
          </Box>
        </>
      </Modal>
    </LocalizationProvider>
  );
};

export default StickerCategoryReactivateModal;

StickerCategoryReactivateModal.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  categoryStickers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetch: PropTypes.func.isRequired,
  endStickers: PropTypes.func.isRequired,
};
